@import "../../styles/theme.scss";

.anchorOriginBottomCenter {
  margin-bottom: 56px;
}

.contentRoot {
  width: 100%;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.87);
  font-family: $font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: white;
}

.icon {
  color: white;
  align-items: center;
}
