$color-backdrop: rgba(17, 48, 119, 0.2);
$color-background: #E8EBF2;
$color-disabled: #E8EBF2;
$color-primary: #113077;
$color-primary-button: #126DFF;
$color-inactive-button: rgba(0, 0, 0, 0.12);
$color-secondary: #00823B;
$color-text-gray: #E8EBF2;
$color-background-blue: #072C66;
$color-warning: #C3112B;
$color-warning-light:#FBEEF0;
$color-warning-dark:#4e0711;
$color-text-black:#000000;
$color-text-grayscale-black: #13151F;
$color-white:#FFFFFF;
$color-neutral-gray:#EEEEEE;
$color-background-primary:#EFF5FE;
$color-background-gray:#FAFAFA;
$color-basic-blue:#126DFF;
$color-medium-blue: #c3d9f9;
$color-text-version-gray:#757575;
$color-background-green:#EBF5EC;
$color-green:#078814;
$color-divider-gray: #E0E0E0;
$color-orange: #EEAE61;
$color-text-neutral-black:rgba(0, 0, 0, 0.7);
$color-neutral-black: #212121;
$font-family: "Roboto", "Helvetica", "Arial", sans-serif;
$font-family-bold: "Source Sans Pro Bold";
$font-family-regular: "Source Sans Pro Regular";
$font-family-light : "Source Sans Pro Light";
$font-family-black : "Source Sans Pro Black";
$font-family-semiBold : "Source Sans Pro SemiBold";

// Breakpoints from theme
$screen-sm-min: 600px;
$screen-md-min: 960px;
$screen-lg-min: 1280px;
$screen-xl-min: 1920px;

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
      @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
      @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
      @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
      @content;
  }
}